const { getNextWorkDay } = useDeliveryUtils()

const NOVAENGEL_SUPPLIER_ID = 258
const NATURLEADER_SUPPLIER_ID = 423
const NOVAENGEL_HORIZONS_SUPPLIER_ID = 828
const DISTRIVET_SUPPLIER_ID = 778

const supplierIds: number[] = [
  NOVAENGEL_SUPPLIER_ID,
  NATURLEADER_SUPPLIER_ID,
  NOVAENGEL_HORIZONS_SUPPLIER_ID,
  DISTRIVET_SUPPLIER_ID,
]

// Composable function to use crossdocking suppliers
export function useCrossdockingSuppliers() {
  // Get supplier IDs
  const getSupplierIds = (): number[] => {
    return supplierIds
  }

  // Get supplier ETA
  const getSupplierEta = (
    supplierId: number,
    initialDate: Date = new Date()
  ): Date => {
    const service = getSupplier(supplierId)
    const { getEta } = service
    return getEta(initialDate)
  }

  // Get supplier limit hour
  const getSupplierLimitHour = (supplierId: number): number => {
    const service = getSupplier(supplierId)
    const { getLimitHour } = service
    return getLimitHour()
  }

  // Get supplier service instance
  const getSupplier = (supplierId: number): any => {
    switch (supplierId) {
      case NOVAENGEL_SUPPLIER_ID:
      case NOVAENGEL_HORIZONS_SUPPLIER_ID:
        return useNovaEngelCrossdockingSupplier()
      case NATURLEADER_SUPPLIER_ID:
        return useNaturLeaderCrossdockingSupplier()
      case DISTRIVET_SUPPLIER_ID:
        return useDistrivetCrossdockingSupplier()
      default:
        throw new Error(`Unknown Crossdocking Supplier: ${supplierId}`)
    }
  }

  return {
    getSupplierIds,
    getSupplierEta,
    getSupplierLimitHour,
    getSupplier,
  }
}

function useDistrivetCrossdockingSupplier() {
  const DELIVERY_CUTTING_TIME = 18
  const ARRIVED_TIME = 15
  const { getNextWorkDay } = useDeliveryUtils()

  // Get the ETA (Estimated Time of Arrival) based on the date
  const getEta = (date: Date): Date => {
    const hour = date.getHours()
    let hours: number

    if (hour < ARRIVED_TIME) {
      hours = ARRIVED_TIME - hour + 24
    } else if (hour < DELIVERY_CUTTING_TIME) {
      hours = 24 - (hour - ARRIVED_TIME)
    } else {
      hours = 48 - (hour - ARRIVED_TIME)
    }

    // Adjust the date by the calculated hours
    date.setHours(date.getHours() + hours)

    // Get the next workday after the adjustment
    const nextWorkDay = getNextWorkDay(date)

    return nextWorkDay
  }

  // Get the limit hour for delivery cutoff
  const getLimitHour = (): number => {
    return DELIVERY_CUTTING_TIME
  }

  return {
    getEta,
    getLimitHour,
  }
}

function useNaturLeaderCrossdockingSupplier() {
  const DELIVERY_CUTTING_TIME = 20
  const ARRIVED_TIME = 15
  // Function to get ETA
  function getEta(date: Date): Date {
    const hour = date.getHours()

    let hoursToAdd: number

    if (hour < ARRIVED_TIME) {
      hoursToAdd = ARRIVED_TIME - hour + 24
    } else if (hour < DELIVERY_CUTTING_TIME) {
      hoursToAdd = 24 - (hour - ARRIVED_TIME)
    } else {
      hoursToAdd = 48 - (hour - ARRIVED_TIME)
    }

    date.setHours(date.getHours() + hoursToAdd)

    // Adjust to the next working day
    return getNextWorkDay(date)
  }

  // Function to get limit hour
  function getLimitHour(): number {
    return DELIVERY_CUTTING_TIME
  }

  return {
    getEta,
    getLimitHour,
  }
}

function useNovaEngelCrossdockingSupplier() {
  const DELIVERY_CUTTING_TIME = 12
  const ARRIVED_TIME = 9

  // Function to get ETA
  function getEta(date: Date): Date {
    const hour = date.getHours()
    let hoursToAdd: number

    if (hour < ARRIVED_TIME) {
      hoursToAdd = ARRIVED_TIME - hour + 24
    } else if (hour < DELIVERY_CUTTING_TIME) {
      hoursToAdd = 24 - (hour - ARRIVED_TIME)
    } else {
      hoursToAdd = 48 - (hour - ARRIVED_TIME)
    }

    date.setHours(date.getHours() + hoursToAdd)

    // Adjust to the next working day
    return getNextWorkDay(date)
  }

  // Function to get limit hour
  function getLimitHour(): number {
    return DELIVERY_CUTTING_TIME
  }

  return {
    getEta,
    getLimitHour,
  }
}
