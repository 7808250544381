// composables/useDeliveryUtils.ts

const holidays = [
  '0106',
  '0320',
  '0406',
  '0407',
  '0501',
  '0502',
  '0815',
  '1012',
  '1101',
  '1206',
  '1208',
  '1225',
]

const specialEarlyCutDays = ['1224', '1231']

const {
  GEO_ZONE_CANARIAS,
  GEO_ZONE_PORTUGAL,
  GEO_ZONE_ITALY,
  GEO_ZONE_FRANCE,
  GEO_ZONE_BALEARES,
  GEO_ZONE_GERMANY,
  GEO_ZONE_BELGIUM,
} = useGeozone()

export function useDeliveryUtils() {
  // EXCEPTIONAL DELIVERY DELAYS

  // Get the exceptional delivery delay days
  const getExceptionalDeliveryDelayDays = (): number => {
    return 0
    // return 2;
  }

  // Get the exceptional delivery delay message ID (or null)
  const getExceptionalDeliveryDelayTextId = (): string | null => {
    return null
    // return 'product_est_delivery_delayed';
  }

  // Get the limit hour for a specific GeoZone and confirmed date
  const getLimitHour = (confirmed: Date, gzId: number): number => {
    const isSpecialDay = specialEarlyCutDays.includes(
      formatDate(confirmed, 'md')
    )
    let hour: number

    switch (gzId) {
      case GEO_ZONE_CANARIAS:
      case GEO_ZONE_PORTUGAL:
        hour = isSpecialDay ? 11 : 16
        break
      default:
        hour = isSpecialDay ? 12 : 17
        break
    }

    return hour
  }

  // Get the delivery limit based on the confirmed date and GeoZone ID
  const getDeliveryLimit = (confirmed: Date, geozone_id: number): Date => {
    const limitHour = getLimitHour(confirmed, geozone_id)
    const confirmedHour = confirmed.getHours()

    if (confirmedHour >= limitHour) {
      confirmed.setDate(confirmed.getDate() + 1)
      confirmed.setHours(0, 0, 0, 0)
    }

    confirmed = getNextWorkDay(confirmed)
    let numDays = getDaysForGeozone(geozone_id)
    numDays += getExceptionalDeliveryDelayDays()

    confirmed = walkCalendar(confirmed, numDays)
    confirmed.setHours(23, 59, 59)

    return confirmed
  }

  // Walk the calendar forward by a number of days, skipping holidays and weekends
  const walkCalendar = (start: Date, days: number): Date => {
    let date = new Date(start)
    for (let i = 0; i < days; i++) {
      date.setDate(date.getDate() + 1)
      date = getNextWorkDay(date)
    }

    return date
  }

  // Get the next working day, skipping weekends and holidays
  const getNextWorkDay = (confirmed: Date): Date => {
    if (holidays.includes(formatDate(confirmed, 'md'))) {
      confirmed.setDate(confirmed.getDate() + 1)
      return getNextWorkDay(confirmed)
    }

    if ([6, 0].includes(confirmed.getDay())) {
      confirmed.setDate(confirmed.getDate() + 1)
      return getNextWorkDay(confirmed)
    }
    return confirmed
  }

  // Get the next delivery date for the Canary Islands
  const getNextCanaryDelivery = (submitted: Date): Date => {
    const dayOfWeek = submitted.getDay()
    if (dayOfWeek === 1) {
      submitted.setDate(submitted.getDate() + 3)
    } else if (dayOfWeek === 2) {
      submitted.setDate(submitted.getDate() + 2)
    } else if (dayOfWeek === 3 && submitted.getHours() > 18) {
      submitted.setDate(submitted.getDate() + 7)
    } else if (dayOfWeek === 3) {
      submitted.setDate(submitted.getDate() + 1)
    } else if (dayOfWeek === 4) {
      submitted.setDate(submitted.getDate() + 7)
    } else if (dayOfWeek === 5) {
      submitted.setDate(submitted.getDate() + 6)
    } else if (dayOfWeek === 6) {
      submitted.setDate(submitted.getDate() + 5)
    } else if (dayOfWeek === 0) {
      submitted.setDate(submitted.getDate() + 4)
    }

    submitted.setDate(submitted.getDate() + 5)
    submitted.setHours(23, 59, 59)

    return submitted
  }

  // Helper function to format dates (md: month-day)
  const formatDate = (date: Date, format: string): string => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    return format === 'md' ? month + day : ''
  }

  // Helper function to get the number of days required for delivery by GeoZone
  const getDaysForGeozone = (geozone_id: number): number => {
    switch (geozone_id) {
      case GEO_ZONE_ITALY:
      case GEO_ZONE_FRANCE:
      case GEO_ZONE_BALEARES:
      case GEO_ZONE_GERMANY:
        return 2
      case GEO_ZONE_BELGIUM:
        return 3
      default:
        return 1
    }
  }

  return {
    getExceptionalDeliveryDelayDays,
    getExceptionalDeliveryDelayTextId,
    getLimitHour,
    getDeliveryLimit,
    getNextWorkDay,
    walkCalendar,
    getNextCanaryDelivery,
  }
}
