import { useGeozone } from './geozone'

const { GEO_ZONE_CANARIAS } = useGeozone()

export const CASARRUBUELOS = 1
export const CANARIAS = 2

export const PROFILE_CROSSDOCKING = 4

export const GENERAL_TIENDAS = 15
export const CROSSSDOCKING_ENGEL = 21
export const CROSSSDOCKING_NATURALEADER = 22
export const CROSSSDOCKING_DISTRIVET = 23

const inputStockLocations: number[] = [CASARRUBUELOS]

const crossdocking: number[] = [CASARRUBUELOS]

const privateLabelStockLocations: number[] = [CASARRUBUELOS]

const alias: Record<number, string> = {
  [CASARRUBUELOS]: 'Península',
  [CANARIAS]: 'Canarias',
}

const backupStockLocations: Record<number, number> = {
  [CANARIAS]: CASARRUBUELOS,
}

// Composable function to use StockLocation
export function useStockLocation() {
  // Get alias for stock location
  const getAlias = (id: number): string | undefined => {
    return alias[id]
  }

  // Get stock location for a geo zone
  const getForGeozone = (geoZoneId: number): number => {
    if (geoZoneId === GEO_ZONE_CANARIAS) {
      return CANARIAS
    }
    return CASARRUBUELOS
  }

  // Get stock locations for locale
  const getForLocale = (locale: string): number[] => {
    if (locale.toUpperCase() === 'ES') {
      return [CASARRUBUELOS, CANARIAS]
    }
    return [CASARRUBUELOS]
  }

  // Get backup stock location
  const getBackupStockLocation = (
    stockLocationId: number
  ): number | undefined => {
    return backupStockLocations[stockLocationId]
  }

  // Get default stock location for locale
  const getDefaultForLocale = (locale: string): number => {
    return CASARRUBUELOS
  }

  return {
    inputStockLocations,
    crossdocking,
    privateLabelStockLocations,
    getAlias,
    getForGeozone,
    getForLocale,
    getBackupStockLocation,
    getDefaultForLocale,
  }
}
