import type { Geozone } from '@@/../types/common'

export function useGeozone() {
  const SPAIN = 240
  const GEO_ZONE_PENINSULA = 2
  const GEO_ZONE_BALEARES = 3
  const GEO_ZONE_CANARIAS = 4
  const GEO_ZONE_CEUTA_MELILLA = 5
  const GEO_ZONE_PORTUGAL = 7
  const GEO_ZONE_FRANCE = 8
  const GEO_ZONE_GERMANY = 9
  const GEO_ZONE_ITALY = 10
  const GEO_ZONE_BELGIUM = 11
  const GEO_ZONE_REINO_UNIDO = 12
  const GEO_ZONE_IRLANDA = 13
  const GEO_ZONE_PAISES_BAJOS = 14
  const GEO_ZONE_HUNGRIA = 15
  const GEO_ZONE_REP_CHECA = 16
  const GEO_ZONE_POLONIA = 17
  const GEO_ZONE_DINARMACA = 18
  const GEO_ZONE_FINLANDIA = 19
  const GEO_ZONE_GRECIA = 20
  const GEO_ZONE_NORUEGA = 21
  const GEO_ZONE_RUMANIA = 22
  const GEO_ZONE_REP_ESLOVACA = 23
  const GEO_ZONE_SUECIA = 24
  const GEO_ZONE_SUIZA = 25
  const GEO_ZONE_TURQUIA = 26
  const GEO_ZONE_AUSTRIA = 27
  const GEO_ZONE_SLOVENIA = 28

  const DEFAULT_CURRENCY_SYMBOL = '€'
  const DEFAULT_CURRENCY_CODE = 'EUR'

  // Names for geo zones
  const names = {
    [GEO_ZONE_PENINSULA]: 'Península',
    [GEO_ZONE_BALEARES]: 'Baleares',
    [GEO_ZONE_CANARIAS]: 'Canarias',
    [GEO_ZONE_PORTUGAL]: 'Portugal',
    [GEO_ZONE_FRANCE]: 'France',
    [GEO_ZONE_GERMANY]: 'Deutschland',
    [GEO_ZONE_ITALY]: 'Italia',
    [GEO_ZONE_BELGIUM]: 'Belgique',
  }

  // Offsets for geo zones
  const offsets = {
    [GEO_ZONE_CANARIAS]: '+1',
    [GEO_ZONE_PORTUGAL]: '+1',
    [GEO_ZONE_REINO_UNIDO]: '+1',
    [GEO_ZONE_IRLANDA]: '+1',
    [GEO_ZONE_FINLANDIA]: '-2',
    [GEO_ZONE_GRECIA]: '-2',
    [GEO_ZONE_RUMANIA]: '-2',
    [GEO_ZONE_TURQUIA]: '-3',
  }

  const getName = (id: number): string => {
    return names[id] ?? id.toString()
  }

  const getOffset = (id: number): string | undefined => {
    return offsets[id]
  }

  return {
    GEO_ZONE_PENINSULA,
    GEO_ZONE_BALEARES,
    GEO_ZONE_CANARIAS,
    GEO_ZONE_CEUTA_MELILLA,
    GEO_ZONE_PORTUGAL,
    GEO_ZONE_FRANCE,
    GEO_ZONE_GERMANY,
    GEO_ZONE_ITALY,
    GEO_ZONE_BELGIUM,
    GEO_ZONE_REINO_UNIDO,
    GEO_ZONE_IRLANDA,
    GEO_ZONE_PAISES_BAJOS,
    GEO_ZONE_HUNGRIA,
    GEO_ZONE_REP_CHECA,
    GEO_ZONE_POLONIA,
    GEO_ZONE_DINARMACA,
    GEO_ZONE_FINLANDIA,
    GEO_ZONE_GRECIA,
    GEO_ZONE_NORUEGA,
    GEO_ZONE_RUMANIA,
    GEO_ZONE_REP_ESLOVACA,
    GEO_ZONE_SUECIA,
    GEO_ZONE_SUIZA,
    GEO_ZONE_TURQUIA,
    GEO_ZONE_AUSTRIA,
    GEO_ZONE_SLOVENIA,
    DEFAULT_CURRENCY_CODE,
    DEFAULT_CURRENCY_SYMBOL,
    getName,
    getOffset,
  }
}
