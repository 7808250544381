import { useStockLocation } from './stocklocations'
import { useCrossdockingSuppliers } from './suppliers'
import { CASARRUBUELOS } from './stocklocations'
import { useDeliveryUtils } from './deliveryutils'
import { useGeozone } from './geozone'
import { useShippingMethods } from './shippingmethods'

const { FAMILY_HOME_DELIVERY_24H } = useShippingMethods()
// Constantes
const SOURCE_NUTRITIENDA = 0
const NUTRITIENDA_CUTOFF_TIME = 17
const DEFAULT_SHIPPING_METHOD_ID = FAMILY_HOME_DELIVERY_24H
const FREE_SHIPPING_THRESHOLD = 25

// Tipos
type DeliveryTimeCalculatorResult = {
  delivery_time: Date
  delivery_text_id: string | undefined
  delivery_text_params: Record<string, any>
  expires_at_seconds: number
  delivery_location_text_id?: string | null
  delivery_location_text_params?: Record<string, any> | null
  debug_info: Record<string, any>
}

const { GEO_ZONE_PENINSULA, getOffset } = useGeozone()
const { getSupplierEta, getSupplierLimitHour } = useCrossdockingSuppliers()
const { getForGeozone } = useStockLocation()
const {
  getNextWorkDay,
  getDeliveryLimit,
  getNextCanaryDelivery,
  getLimitHour,
} = useDeliveryUtils()

// Métodos privados
const calculateCrossdocking = (
  source: number,
  geozone_id: number
): DeliveryTimeCalculatorResult => {
  let date = new Date()
  date = getSupplierEta(source, date)
  const stock_location_id = CASARRUBUELOS

  const result = _calculate(date, stock_location_id, geozone_id)

  // Overwrite valid until if needed
  const valid_until = new Date()
  const current_hour = valid_until.getHours()
  const cutoff_hour = getSupplierLimitHour(source)

  if (current_hour >= cutoff_hour) {
    valid_until.setDate(valid_until.getDate() + 1)
  }

  getNextWorkDay(valid_until)
  valid_until.setHours(cutoff_hour, 0)

  const expires_at_seconds =
    (valid_until.getTime() - new Date().getTime()) / 1000

  result.expires_at_seconds = expires_at_seconds
  result.delivery_text_params = { '{time}': cutoff_hour }

  return result
}

const calculateRegular = (
  stock_location_id: number,
  geozone_id: number
): DeliveryTimeCalculatorResult => {
  const date = new Date()
  return _calculate(date, stock_location_id, geozone_id)
}

const _calculate = (
  date: Date,
  stock_location_id: number,
  geozone_id: number
): DeliveryTimeCalculatorResult => {
  const offset = getOffset(geozone_id)

  if (offset) {
    date.setHours(applyOperation(date.getHours(), offset))
  }

  const confirmed = new Date(date)

  const gz_stock_location_id = getForGeozone(geozone_id)
  let delivery_time, delivery_location_text_id, delivery_location_text_params

  if (stock_location_id === gz_stock_location_id) {
    delivery_time = getDeliveryLimit(confirmed, geozone_id)
  } else {
    delivery_time = getNextCanaryDelivery(confirmed)
    delivery_location_text_id = 'product_est_delivery_backup_sl'
    delivery_location_text_params = { '{location}': 'Peninsula' }
  }

  const cutoff_hour = getLimitHour(confirmed, geozone_id)

  const valid_until = new Date()
  const current_hour = valid_until.getHours()
  let delivery_text_id

  if (current_hour < cutoff_hour) {
    delivery_text_id = 'product_est_delivery_limit_today'
  } else {
    valid_until.setDate(valid_until.getDate() + 1)
  }

  getNextWorkDay(valid_until)

  const same_day = valid_until.getDate() === new Date().getDate()
  const master_cutoff_hour = getLimitHour(new Date(), GEO_ZONE_PENINSULA)

  if (same_day) {
    valid_until.setHours(master_cutoff_hour, 0)
  } else {
    valid_until.setHours(0, 0)
  }

  const expires_at_seconds =
    (valid_until.getTime() - new Date().getTime()) / 1000

  return {
    delivery_time,
    delivery_text_id,
    delivery_text_params: { '{time}': cutoff_hour },
    expires_at_seconds,
    delivery_location_text_id,
    delivery_location_text_params,
    debug_info: {
      dbg_now: new Date().toUTCString(),
      dbg_date: date.toUTCString(),
      dbg_current_hour: current_hour,
      dbg_cutoff_hour: cutoff_hour,
      dbg_confirmed: confirmed.toUTCString(),
      dbg_valid_until: valid_until.toUTCString(),
      dbg_sameday: same_day,
      dbg_master_cutoff_hour: master_cutoff_hour,
    },
  }
}

// Composable principal
export const useETACalculator = () => {
  const calculate = (
    source: number,
    stock_location_id: number,
    geozone_id: number,
    locale: string = 'es'
  ) => {
    let data
    if (source > 0) {
      data = calculateCrossdocking(source, geozone_id)
    } else {
      data = calculateRegular(stock_location_id, geozone_id)
    }

    const text = formatText(data, locale)
    return text
  }

  return {
    calculate,
  }
}

const applyOperation = (value: number, operation: string): number => {
  const operator = operation[0]
  const amount = parseFloat(operation.slice(1))

  switch (operator) {
    case '+':
      return value + amount
    case '-':
      return value - amount
    default:
      throw new Error('Unsupported operation')
  }
}

const TEXT_TRANSLATIONS: {
  [key: string]: Record<string, string>
} = {
  es: {
    product_est_delivery:
      'Entrega {{delivery_info}} entre el <span style="font-weight: bold;">{date}</span>',
    product_est_delivery_delayed:
      '&#9888; Debido a la situación meteorológica actual, la entrega {{delivery_info}} es entre el <span style="font-weight: bold;">{date}</span>.',
    product_est_delivery_limit_today: 'Haz tu pedido en {{countdown}}',
    product_est_delivery_limit_tomorrow:
      'Haz tu pedido antes de mañana a las {{countdown}}',
    product_est_delivery_backup_sl: 'Envío desde Almacén Península',
  },
}

const MONTHS = {
  es: {
    0: 'enero',
    1: 'febrero',
    2: 'marzo',
    3: 'abril',
    4: 'mayo',
    5: 'junio',
    6: 'julio',
    7: 'agosto',
    8: 'septiembre',
    9: 'octubre',
    10: 'noviembre',
    11: 'diciembre',
  },
}

const formatText = (
  etaCalculated: DeliveryTimeCalculatorResult,

  locale: string
) => {
  const delivery_text_id = etaCalculated?.delivery_text_id
  const delivery_text_params = etaCalculated?.delivery_text_params
  const delivery_location_text_id = etaCalculated?.delivery_location_text_id
  const delivery_location_text_params =
    etaCalculated?.delivery_location_text_params

  let text = ''

  let delivery_text = TEXT_TRANSLATIONS[locale]['product_est_delivery']
  const delivery_time = etaCalculated?.delivery_time
  const delivery_time_date = new Date(delivery_time)

  const format_delivery_date = delivery_time_date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
  })

  const next_day = new Date(delivery_time)
  next_day.setDate(next_day.getDate() + 1)
  getNextWorkDay(next_day)

  const nextDay = next_day.getDate()

  const finalDate = `${delivery_time_date.getDate()} - ${nextDay}  ${format_delivery_date.replace(`${delivery_time_date.getDate()}`, '')}`

  text = `${delivery_text.replace('{date}', `${finalDate}`)}. `

  if (delivery_text_id) {
    let delivery_text = TEXT_TRANSLATIONS[locale][delivery_text_id]
    for (const key in delivery_text_params) {
      delivery_text = delivery_text.replace(key, delivery_text_params[key])
    }
    text += `${delivery_text}.`
  }

  return {
    text,
    cutt_off: delivery_text_params['{time}'],
    last_date: next_day.toISOString(),
    checkout_text: finalDate,
  }
}
